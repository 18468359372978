import React, { ReactElement } from 'react';
import Image from 'src/components/Image';

import DefaultTemplate from 'src/templates/default';
// handle pages that dont exist anymore in new docs instead of showing 404
import LegacyDocsRedirects from 'src/components/docs/DocsBeta/LegacyDocsRedirects';

import { Column, Container, Row, Section, Box } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Button } from 'src/components-v3/Homepage/Buttons/Button';

import { Locales } from 'src/lib/constants';
import { mutateHardCodedLinks } from 'src/lib/utils';

import * as enUs404 from 'src/store/404';
import * as enEu404 from 'src/store/en-eu/404';
import * as enGb404 from 'src/store/en-gb/404';

const error404PerLocale = {
  [Locales.EN_US]: enUs404,
  [Locales.EN_EU]: enEu404,
  [Locales.EN_GB]: enGb404,
};

const Error404 = ({
  locale = Locales.EN_US,
}: {
  locale?: string;
}): ReactElement => {
  const {
    metaData,
    body: { header = '', description = '', button = '' },
  } = mutateHardCodedLinks({
    data: error404PerLocale[locale] || error404PerLocale[Locales.EN_US],
    locale,
  });

  return (
    <>
      <LegacyDocsRedirects />

      <DefaultTemplate
        {...metaData}
        noIndex={true}
        locale={locale}
        cmsTheme='web 3.0'
      >
        <Section
          sx={{
            padding: {
              xs: '116px 0 22px',
              md: '147px 0 30px',
              xl: '138px 0 47px',
            },
            backgroundImage:
              'url(https://images.ctfassets.net/ss5kfr270og3/2DqVDnVdYeEFez70ye2AEr/68041298381a06012ce1fd1aecb3563c/404-background-image.jpg?q=80&fm=webp)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Container>
            <Row sx={{ alignItems: { xl: 'center' } }}>
              <Column
                xs={20}
                xsOffset={2}
                sm={18}
                smOffset={0}
                md={15}
                mdOffset={0}
                lg={15}
                lgOffset={2}
                xl={8}
                xlOffset={2}
                sx={{
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                <Typography
                  variant='h1'
                  component='h1'
                  sx={{
                    lineHeight: { xs: '114%', xl: '104%' },
                    letterSpacing: '-2px',
                    color: 'base/minted/100',
                    background:
                      'linear-gradient(77deg, #CDF5FD 15%, #D8FEF3 40.73%, #E8FED7 66.45%)',
                    backgroundClip: 'text',
                    '-webkit-text-fill-color': 'transparent',
                    '-webkit-background-clip': 'text',
                    pb: { xs: '0px', xl: '4px' },
                    mb: { xs: '1rem', xl: '1.6rem' },
                  }}
                >
                  {header}
                </Typography>

                <Typography
                  variant='p'
                  component='p'
                  sx={{
                    fontSize: '2.0rem',
                    fontWeight: 600,
                    color: 'base/minted/100',
                    letterSpacing: '-0.3px',
                    mb: { xs: '3rem', sm: '4rem' },
                    maxWidth: { sm: '374px' },
                  }}
                >
                  {description}
                </Typography>

                <div>
                  <Button href='/'>{button}</Button>
                </div>
              </Column>

              <Column xs={24} sm={18} smOffset={6} xl={12} xlOffset={1}>
                <Box sx={{ mt: { sm: '-110px', xl: 0 } }}>
                  <Image
                    src='https://images.ctfassets.net/ss5kfr270og3/7uWmEPdPUxp4zr8vUokL78/037d9ce0d8ccbf9bd72e0f2204842ebe/404-error-state-2.png?q=80&fm=webp&w=1584'
                    alt='404 error'
                  />
                </Box>
              </Column>
            </Row>
          </Container>
        </Section>
      </DefaultTemplate>
    </>
  );
};

export default Error404;
