import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import React from 'react';
import styled from 'threads5/styles/styled';
import Box from 'threads5/Box';

const StyledButton = styled(Box)<{ variant: variants }>(
  ({ theme, variant }) => {
    const variants = {
      green: {
        background: 'linear-gradient(91deg, #D5FFD2 0.55%, #FFF 97.3%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(92deg, #D5FFD2 77.16%, #FFF 93.96%)',
        },
      },
      blue: {
        background: 'linear-gradient(246deg, #FFF 15.6%, #AEFFF3 93.94%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(272deg, #FFF 3.4%, #AEFFF3 28.08%)',
        },
      },
      purple: {
        background: 'linear-gradient(272deg, #FFF 0%, #E4E4FF 100%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(271deg, #FFF 3.22%, #E4E4FF 33.94%)',
        },
      },
      pink: {
        background: 'linear-gradient(272deg, #FFF 0%, #FBE5FF 100%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(273deg, #FFF 3.45%, #FBE5FF 19.69%)',
        },
      },
      elizabeth: {
        background: 'linear-gradient(127deg, #56DDF7 1.49%, #8B8BF9 78.67%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(136deg, #56DDF7 61.49%, #8B8BF9 92.31%)',
        },
      },
      benjamin: {
        background: 'linear-gradient(132deg, #42F0CD 0%, #0DADF2 82.97%)',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
        '&:hover, &:focus': {
          background: 'linear-gradient(141deg, #42F0CD 67.77%, #0DADF2 93.58%)',
        },
      },
    };

    return {
      display: 'inline-flex',
      padding: '8px 30px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '46px !important',
      color: theme.palette['base/sky/1000'],
      fontFamily: theme.typography.cern,
      fontSize: theme.spacing(2.5),
      fontWeight: 600,
      lineHeight: '150%',
      transition: 'background, box-shadow, transform',
      transitionDuration: '200ms',
      transitionTimingFunction: 'ease-in-out',
      width: 'max-content',
      ...variants[variant],
      '&:active': {
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0)',
        transform: 'scale(0.98)',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8rem',
      },
    };
  },
);

type variants = 'green' | 'blue' | 'purple' | 'pink' | 'elizabeth' | 'benjamin';

interface IBtnBaseProps {
  className?: string;
  children: React.ReactNode;
  isInverted?: boolean;
  sx?: SxProps<Theme>;
  trackingId?: string;
  variant?: variants;
  tabIndex?: number;
  backgroundColor?: string;
}

interface IBtnWithHref extends IBtnBaseProps {
  href: string;
  onClick?: never;
  target?: '_blank' | '_parent' | '_self' | '_top';
  autofocus?: never;
  disabled?: never;
  form?: never;
  formAction?: never;
  formEnctype?: never;
  formMethod?: never;
  formNoValidate?: never;
  formTarget?: never;
  name?: never;
  type?: never;
  value?: never;
}

interface IBtnWithClick extends IBtnBaseProps {
  href?: never;
  onClick: (event?) => void;
  target?: never;
  autofocus?: never;
  disabled?: never;
  form?: never;
  formAction?: never;
  formEnctype?: never;
  formMethod?: never;
  formNoValidate?: never;
  formTarget?: never;
  name?: never;
  type?: never;
  value?: never;
}

interface IBtnWithType extends IBtnBaseProps {
  href?: never;
  onClick?: (event?) => void;
  target?: never;
  autofocus?: boolean;
  disabled?: boolean;
  form?: string;
  formAction?: string;
  formEnctype?:
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data'
    | 'text/plain';
  formMethod?: 'get' | 'post';
  formNoValidate?: boolean;
  formTarget?: '_blank' | '_self' | '_parent' | '_top' | string;
  name?: string;
  type: 'button' | 'reset' | 'submit';
  value?: string;
}

export type IButtonProps = IBtnWithClick | IBtnWithType | IBtnWithHref;

export const Button = ({
  variant = 'green',
  href,
  children,
  ...rest
}: IButtonProps) => {
  if (href) {
    return (
      <StyledButton
        component='a'
        href={href}
        variant={variant}
        {...(rest as IBtnWithHref)}
      >
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      component='button'
      variant={variant}
      {...(rest as IBtnWithClick | IBtnWithType)}
    >
      {children}
    </StyledButton>
  );
};
